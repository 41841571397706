import i18n from "../tools/i18n.js";

export const BRANCH_OFFICE_JURISDICTION = () => {
  return [
    { text: i18n.t("headers.branch_office_jurisdiction.supplier_ID"), value: "idSupplier" },
    { text: i18n.t("headers.branch_office_jurisdiction.supplier_name"), value: "name" },
    { text: i18n.t("headers.branch_office_jurisdiction.RFC"), value: "supplierKey" },
  ];
};

export const REMMISION = () => {
  return [
    { text: i18n.t("headers.remmision.remmision_id"), value: "idRemmision", width: "10%" },
    { text: "Factura", value: "invoice", width: "15%" },
    { text: i18n.t("headers.remmision.remission_reference"), value: "remissionKey", width: "15%" },
    { text: "Generada por", value: "userName", width: "15%" },
    { text: i18n.t("headers.remmision.type_remission"), value: "remissionType", width: "10%" },
    { text: i18n.t("headers.remmision.order"), value: "requisition" },
    { text: i18n.t("headers.remmision.supplier"), value: "supplierName" },
    { text: i18n.t("headers.remmision.registration_date"), value: "dateInput" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, width: "15%", align: 'center' }
  ];
};

export const REMISSION_TRANSFER = () => {
  return [
    { text: i18n.t("headers.remmision_transfer.remmision_id"), value: "idRemmision", width: "15%" },
    //{ text: "Factura", value: "invoice", width: "15%" },
    { text: i18n.t("headers.remmision_transfer.remission_reference"), value: "remissionKey" },
    { text: i18n.t("headers.remmision_transfer.supplier"), value: "supplierName" },
     //{ text: i18n.t("headers.remmision_transfer.motive"), value: "remissionSubtype", width: "10%" },
     { text: i18n.t("headers.remmision_transfer.registration_date"), value: "dateInput", width: "10%" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center', width: "10%" }
  ];
};

export const REQUISITION = () => {
  return [
    { text: i18n.t("headers.requisition.order_ID"), value: "idRequisition" },
    { text: i18n.t("headers.requisition.order_reference"), value: "requisitionKey" },
    { text: i18n.t("headers.requisition.name_tender"), value: "biddingKey" },
    { text: i18n.t("headers.requisition.order_date"), value: "dateRequisition" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false }
  ];
};

export const CREATE_REMMISION = () => {
  return [
    { text: i18n.t("headers.create_remmision.financing"), value: "fiscalfund" },
    { text: i18n.t("headers.create_remmision.causes"), value: "productKey" },
    { text: i18n.t("headers.create_remmision.product"), value: "name" },
    { text: i18n.t("headers.create_remmision.presentation"), value: "unit" },
    { text: i18n.t("headers.create_remmision.expiration"), value: "expirationDate" },
    { text: i18n.t("headers.create_remmision.lot"), value: "lot" },
    { text: i18n.t("headers.create_remmision.quantity"), value: "quantity" },
    { text: i18n.t("headers.create_remmision.cost"), value: "costo" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ];
};

export const CREATE_REMMISION_UPDATE = () => {
  return [
    { text: i18n.t("headers.create_remmision_update.financing"), value: "fiscalFund.key", width: "10%" },
    { text: i18n.t("headers.create_remmision_update.causes"), value: "products.productKey" },
    { text: i18n.t("headers.create_remmision_update.product"), value: "products.description" },
    { text: i18n.t("headers.create_remmision_update.expiration"), value: "expirationDate", width: "10%" },
    { text: i18n.t("headers.create_remmision_update.lot"), value: "lot", width: "10%" },
    { text: i18n.t("headers.create_remmision_update.quantity"), value: "quantity", width: "10%" },
    { text: i18n.t("headers.create_remmision_update.cost"), value: "unitPrice", width: "10%" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ];
};



export const STOCK = () => {
  return [
    { text: i18n.t("headers.stock.id_stock"), value: "idStock", width: "8%" },
    { text: i18n.t("headers.stock.causes"), value: "productKey" },
    { text: i18n.t("headers.stock.product"), value: "productName", width: "30%" },
    { text: i18n.t("headers.stock.lot"), value: "lote", width: "6%" },
    { text: i18n.t("headers.stock.expiration"), value: "expirationDate", width: "12%" },
    { text: i18n.t("headers.stock.financing"), value: "fiscalFund", width: "12%" },
    { text: i18n.t("headers.stock.quantity"), value: "quantity", width: "5%" },
    { text: i18n.t("headers.stock.location"), value: "locationKey", width: "5%" },
    { text: i18n.t("headers.stock.valid"), value: "validDate", width: "200px", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, width: "8%", align: 'center' }
  ];
};

export const EXPIRED_STOCK = () => {
  return [
    { text: i18n.t("headers.stock.id_stock"), value: "idStock", width: "8%" },
    { text: i18n.t("headers.stock.causes"), value: "productKey" },
    { text: i18n.t("headers.stock.product"), value: "productName", width: "30%" },
    { text: i18n.t("headers.stock.lot"), value: "lote", width: "6%" },
    { text: i18n.t("headers.stock.expiration"), value: "expirationDate", width: "12%" },
    { text: i18n.t("headers.stock.financing"), value: "fiscalFund", width: "12%" },
    { text: i18n.t("headers.stock.quantity"), value: "quantity", width: "10%" },
    { text: i18n.t("headers.stock.location"), value: "locationKey", width: "10%" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, width: "8%", align: 'center' }
  ];
};

export const WAREHOUSE_NON_CONFORM = () => {
  return [
    { text: i18n.t("headers.warehouse_non_conform.id_health_alert"), value: "healthAlertId", width: "6%" },
    { text: i18n.t("headers.warehouse_non_conform.alert_key"), value: "alertKey", width: "10%" },
    { text: i18n.t("headers.warehouse_non_conform.reason"), value: "reason" },
    { text: i18n.t("headers.warehouse_non_conform.id_stock"), value: "idStock", },
    { text: i18n.t("headers.warehouse_non_conform.causes"), value: "productKey" },
    { text: i18n.t("headers.warehouse_non_conform.product"), value: "productName", width: "30%" },
    { text: i18n.t("headers.warehouse_non_conform.lot"), value: "lote", width: "6%" },
    { text: i18n.t("headers.warehouse_non_conform.expiration"), value: "expirationDate", width: "12%" },
    { text: i18n.t("headers.warehouse_non_conform.financing"), value: "fiscalFund", width: "12%" },
    { text: i18n.t("headers.warehouse_non_conform.quantity"), value: "quantity", width: "5%" },
    { text: i18n.t("headers.warehouse_non_conform.location"), value: "locationKey", width: "5%" },
    { text: i18n.t("headers.warehouse_non_conform.valid"), value: "validDate", width: "200px", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, width: "4%", align: 'center' }
  ];
};


export const PERMISIONS_ROLE = () => {
  return [
    { text: i18n.t("headers.permision_role.permission"), value: "description" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const ROLES = () => {
  return [
    /* { text: i18n.t("headers.role.id_role"), value: "idRole", sortable: false }, */
    { text: i18n.t("headers.role.name"), value: "name", sortable: false },
    { text: i18n.t("headers.role.description"), value: "description", sortable: false },
    { text: i18n.t("headers.role.quantity_permits"), value: "totalPermmisions", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, width: "10%" }
  ]
}

export const STARTING_ORDER = () => {
  return [
    { text: i18n.t("headers.starting_order.ID_shipment"), value: "idShipment", width: "10%" },
    { text: i18n.t("headers.starting_order.ID_starting_order"), value: "orderId", width: "10%" },
    { text: i18n.t("headers.starting_order.generated_by"), value: "userFullName" },
    { text: i18n.t("headers.starting_order.destination"), value: "branchOfficeDestinationName" },
    { text: i18n.t("headers.starting_order.create_date"), value: "createDate", width: "14%", align: 'center' },
    { text: i18n.t("headers.starting_order.date_of_shipment"), value: "dateShipment", width: "14%", align: 'center' },
    { text: i18n.t("headers.starting_order.status"), value: "statusText", sortable: false, align: 'center' },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center', width: "10%", sortable: false }
  ]
}

export const ORDER_DETAIL_ACTION = () => {
  return [
    { text: i18n.t("headers.order_detail_action.financing"), value: "fiscalFoundKey" },
    { text: i18n.t("headers.order_detail_action.causes"), value: "productKey" },
    { text: i18n.t("headers.order_detail_action.product"), value: "productDescription", width: "35%" },
    { text: i18n.t("headers.order_detail_action.lot"), value: "lot" },
    { text: i18n.t("headers.order_detail_action.expiration"), value: "expirationDate" },
    { text: i18n.t("headers.order_detail_action.quantity"), value: "quantity", },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, width: "10%", align: 'center' }
  ]
}

export const ORDER_DETAIL = () => {
  return [
    { text: i18n.t("headers.order_detail.financing"), value: "fiscalFoundKey" },
    { text: i18n.t("headers.order_detail.causes"), value: "productKey" },
    { text: i18n.t("headers.order_detail.product"), value: "productDescription", width: "35%" },
    { text: i18n.t("headers.order_detail.lot"), value: "lot" },
    { text: i18n.t("headers.order_detail.expiration"), value: "expirationDate" },
    { text: i18n.t("headers.order_detail.quantity"), value: "quantity" },
  ]
}

export const SHIPMENTS_CANCELLATION = () => {
  return [
    { text: i18n.t("headers.shipments_cancellation.ID_shipment"), value: "idShipment" },
    { text: i18n.t("headers.shipments_cancellation.ID_starting_order"), value: "orderId" },
    { text: i18n.t("headers.shipments_cancellation.Shipping_reason_text"), value: "shippingReasonText", sortable: false },
    { text: i18n.t("headers.shipments_cancellation.generated_by"), value: "userFullName" },
    { text: i18n.t("headers.shipments_cancellation.branch_origin"), value: "branchOfficeOriginName" },
    { text: i18n.t("headers.shipments_cancellation.destination"), value: "branchOfficeDestinationName", width: "40%" },
    { text: i18n.t("headers.shipments_cancellation.date_of_departure"), value: "dateShipment" },
    { text: i18n.t("headers.shipments_cancellation.status"), value: "statusShipment", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const DISPENSING_CANCELLATION = () => {
  return [
    { text: i18n.t("headers.dispensing_cancellation.id_recipe"), value: "idPrescription" },
    { text: i18n.t("headers.dispensing_cancellation.Prescription_type_name"), value: "prescriptionType", sortable: false },
    { text: i18n.t("headers.dispensing_cancellation.recipe_key"), value: "prescriptionKey" },
    { text: i18n.t("headers.dispensing_cancellation.beneficiary"), value: "fullName", sortable: false  },
    { text: i18n.t("headers.dispensing_cancellation.branch_origin"), value: "branchOrigin" },
    { text: i18n.t("headers.dispensing_cancellation.generated_by"), value: "generatedBy" },
    { text: i18n.t("headers.dispensing_cancellation.date_dispensing"), value: "dateOutText" },
    { text: i18n.t("headers.dispensing_cancellation.status"), value: "statusPrescription", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const CATEGORIES = () => {
  return [
    { text: i18n.t("headers.categories.id_category"), value: "idCategory", width: "10%" },
    { text: i18n.t("headers.categories.name"), value: "name" },
    { text: i18n.t("headers.categories.description"), value: "description" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const FISCAL_FUND = () => {
  return [
    /* { text: i18n.t("headers.fiscal.ID_financing"), value: "idFiscalFund" }, */
    { text: i18n.t("headers.fiscal.full_name"), value: "name" },
    { text: i18n.t("headers.fiscal.acronym"), value: "key" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const PRODUCTS = () => {
  return [
    /* { text: i18n.t("headers.products.id_product"), value: "idProduct", width: "10%" }, */

    { text: i18n.t("headers.products.causes"), value: "productKey" },
    { text: i18n.t("headers.products.description"), value: "description" },
    //{ text: i18n.t("headers.products.name"), value: "name", width: "15%" },
    { text: i18n.t("headers.products.unit"), value: "unitMeasurementDescription", width: "15%" },
    //{ text: i18n.t("headers.products.categories"), value: "listCategories", width: "15%" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const SUPPLIERS = () => {
  return [
    /* { text: i18n.t("headers.suppliers.id_suppliers"), value: "idSupplier" }, */
    { text: i18n.t("headers.suppliers.name"), value: "name" },
    { text: i18n.t("headers.suppliers.RFC"), value: "supplierKey" },
    { text: "Proveedor con sucursal", value: "hasBranch", align: 'center' },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const JURISDICTIONS = () => {
  return [
    /* { text: i18n.t("headers.jurisdictions.id_jurisdiction"), value: "idJurisdiction" }, */
    { text: i18n.t("headers.jurisdictions.name"), value: "jurisdictionname" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const BRANCH_OFFICE = () => {
  return [
    /* { text: i18n.t("headers.branch.id_branch"), value: "idBranchoffice", width: "10%" }, */
    { text: i18n.t("headers.branch.clues"), value: "branchOfficeKey", width: "8%" },
    { text: i18n.t("headers.branch.id_warehouse"), value: "warehouseId", width: "10%" },
    { text: i18n.t("headers.branch.type_branch"), value: "branchOfficeTypeName", align: 'center', width: "13%", sortable: false, },
    { text: i18n.t("headers.branch.name"), value: "name" },
    { text: i18n.t("headers.branch.address"), value: "fullAddress", width: "12%" },
    { text: i18n.t("headers.branch.jurisdiction"), value: "jurisdiction.jurisdictionname", align: 'center', width: "12%" },
    { text: i18n.t("headers.branch.status"), value: "status", width: "8%" },
    { text: "Recepción", value: "statusForReception", width: "10%", align: 'center' },
    { text: "Reportes", value: "statusForReport", width: "10%", align: 'center' },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, width: "10%", align: 'center' }
  ]
}

export const USERS = () => {
  return [
    //{ text: i18n.t("headers.user.id_user"), value: "idUser", width: "10%" },
    { text: i18n.t("headers.user.full_name"), value: "fullName" },
    { text: i18n.t("headers.user.email"), value: "email" },
    { text: i18n.t("headers.user.main"), value: "mainBranchOfficeName" },
    { text: i18n.t("headers.user.role"), value: "roleName", sortable: false, },
    //{ text: i18n.t("headers.user.totalBranchOffices"), value: "totalBranchOffices", width: "15%", align: 'center',  },
    { text: i18n.t("headers.user.status"), value: "available", width: "10%", sortable: false, },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, width: "12%" }
  ]
}

export const RECEPTION_PENDING = () => {
  return [
    { text: i18n.t("headers.reception_pending.shipping_ID"), value: "shipmentId" },
    { text: i18n.t("headers.reception_pending.order_id"), value: "orderId" },
    { text: i18n.t("headers.reception_pending.reason"), value: "shippingReasonDescription" },
    { text: i18n.t("headers.reception_pending.date_of_shipment"), value: "shippingDateText" },
    { text: i18n.t("headers.reception_pending.origin"), value: "branchOfficeOrigin" },
    { text: i18n.t("headers.reception_pending.driver"), value: "driver" },
    { text: i18n.t("headers.reception_pending.number_placa"), value: "licencePlate" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const PICKING_ORDER = () => {
  return [
    { text: i18n.t("headers.picking_order.financing"), value: "fiscalFoundKey", sortable: false },
    { text: i18n.t("headers.picking_order.causes"), value: "productKey", sortable: false },
    { text: i18n.t("headers.picking_order.product"), value: "productDescription", sortable: false },
    { text: i18n.t("headers.picking_order.lot"), value: "lot", sortable: false },
    { text: i18n.t("headers.picking_order.expiration"), value: "expirationDate", sortable: false },
    { text: i18n.t("headers.picking_order.quantity"), value: "quantity", align: 'center', sortable: false, width: "12%" },
    { text: i18n.t("headers.picking_order.locationkey"), value: "location", sortable: false },
    { text: i18n.t("headers.picking_order.status"), value: "status", align: 'center', sortable: false },
  ]
}

export const PICKING_ORDER_TRANSPARENCY = () => {
  return [
    { text: i18n.t("headers.picking_order.causes"), value: "productKey", sortable: false },
    { text: i18n.t("headers.picking_order.product"), value: "productDescription", sortable: false },
    { text: i18n.t("headers.picking_order.financing"), value: "fiscalFoundKey", sortable: false },
    { text: i18n.t("headers.picking_order.lot"), value: "lot", sortable: false },
    { text: i18n.t("headers.picking_order.expiration"), value: "expirationDate", sortable: false },
    { text: i18n.t("headers.picking_order.quantity"), value: "quantity", align: 'center', sortable: false, width: "12%" }
  ]
}

export const PICKING = () => {
  return [
    { text: "ID Salida", value: "idShipments", width: "10%" },
    { text: "Destino", value: "branchOfficeDestination.name", width: "40%" },
    { text: "Clues", value: "branchOfficeDestination.branchOfficeKey" },
    { text: "Acciones", value: "actions", sortable: false, align: 'center' }
  ]
}

export const PICKING_MOV = () => {
  return [
    { text: i18n.t("headers.picking_mov.id_picking"), value: "idShipments", width: "10%" },
    { text: i18n.t("headers.picking_mov.id_order"), value: "idOrder", width: "10%" },
    { text: i18n.t("headers.picking_mov.destination"), value: "branchOfficeDestination.fullName", width: "20%" },
    { text: i18n.t("headers.picking_mov.picking_manager"), value: "userDataPicking.fullName" },
    { text: i18n.t("headers.picking_mov.date_picking"), value: "userDataPicking.date" }, //Fecha de inicio de picking
    { text: i18n.t("headers.picking_mov.data_validator"), value: "userDataValidator.fullName" },
    { text: i18n.t("headers.picking_mov.date_validator"), value: "userDataValidator.date" }, //Fecha de picking
  ]
}

export const SHIPMENTS_HISTORIC = () => {
  return [
    { text: i18n.t("headers.shipments_historic.order_type"), value: "typeName", sortable: false, width: "8%" },
    { text: i18n.t("headers.shipments_historic.shipping_ID"), value: "idShipments" },
    { text: i18n.t("headers.shipments_historic.id_order"), value: "idOrder" },
    { text: i18n.t("headers.shipments_historic.generated_by"), value: "userBranchOffice.user.fullName" },
    { text: i18n.t("headers.shipments_historic.destination"), value: "branchOfficeDestination.fullName", sortable: false },
    { text: i18n.t("headers.shipments_historic.date_of_issue"), value: "dateShipment" },
    { text: i18n.t("headers.shipments_historic.status"), value: "statusName", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const SHIPMENTS_HISTORY = () => {
  return [
    { text: i18n.t("headers.shipments_historic.order_type"), value: "shippingReasonText", sortable: false, width: "8%" },
    { text: i18n.t("headers.shipments_historic.shipping_ID"), value: "idShipment" },
    { text: i18n.t("headers.shipments_historic.id_order"), value: "orderId" },
    { text: i18n.t("headers.shipments_historic.generated_by"), value: "userFullName" },
    { text: i18n.t("headers.shipments_historic.destination"), value: "branchOfficeDestinationName", sortable: false },
    { text: i18n.t("headers.shipments_historic.date_of_issue"), value: "dateShipment" },
    { text: i18n.t("headers.shipments_historic.status"), value: "statusName", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const RECEPTION_HISTORY = () => {
  return [
    { text: i18n.t("headers.reception_his.reception_ID"), value: "idReception" },
    { text: i18n.t("headers.reception_his.shipping_ID"), value: "shipmentId" },
    { text: i18n.t("headers.reception_his.date_received"), value: "createDateText" },
    { text: i18n.t("headers.reception_his.origin"), value: "branchOfficeOrigin" },
    { text: i18n.t("headers.reception_his.status"), value: "statusText" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const INCIDENCE_ORDER_IN = () => {
  return [
    { text: i18n.t("headers.incidence_in.incident_id"), value: "idIncidence" },
    { text: i18n.t("headers.incidence_in.reason"), value: "reason", width: "30%" },
    { text: i18n.t("headers.incidence_in.type"), value: "typeIncidenceName" },
    { text: i18n.t("headers.incidence_in.incident_date"), value: "dateIncidence" },
    { text: i18n.t("headers.incidence_in.place_incidence"), value: "fullNameOrigin" },
    { text: i18n.t("headers.incidence_in.status"), value: "statusName" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const INCIDENCE_ORDER_OUT = () => {
  return [
    { text: i18n.t("headers.incidence_out.incident_id"), value: "idIncidence", width: "12%", sortable: false },
    { text: i18n.t("headers.incidence_out.type"), value: "typeIncidenceName" },
    { text: i18n.t("headers.incidence_out.reason"), value: "reason", width: "15%" },
    { text: i18n.t("headers.incidence_out.incident_date"), value: "dateIncidence", width: "15%", sortable: false },
    { text: i18n.t("headers.incidence_out.supplier"), value: "supplierName" },
    { text: i18n.t("headers.incidence_out.status"), value: "statusName", width: "10%" },
    { text: i18n.t("headers.incidence_out.confirm"), value: "actions1", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions2", sortable: false, align: 'center' }
  ]
}

export const EMPLOYEE = () => {
  return [
    { text: i18n.t("headers.employee.id_employee"), value: "idEmployee" },
    { text: i18n.t("headers.employee.name"), value: "fullName" },
    { text: i18n.t("headers.employee.date_of_birth"), value: "dateOfBirth" },
    { text: i18n.t("headers.employee.gender"), value: "genderName" },
    { text: i18n.t("headers.employee.phone"), value: "phone" },
    { text: i18n.t("headers.employee.department"), value: "department.name" },
    { text: i18n.t("headers.employee.position"), value: "position.name" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' },
  ]
}

export const DEPARTMENT = () => {
  return [
    /* { text: i18n.t("headers.department.id_department"), value: "idDepartment" }, */
    { text: i18n.t("headers.department.name"), value: "name" },
    { text: i18n.t("headers.department.description"), value: "description" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' },
  ]
}

export const CARGO = () => {
  return [
    { text: i18n.t("headers.position.id_position"), value: "idPosition" },
    { text: i18n.t("headers.position.name"), value: "name" },
    { text: i18n.t("headers.position.description"), value: "description" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' },
  ]
}

export const DOCTOR = () => {
  return [
    { text: i18n.t("headers.doctor.id_doctor"), value: "idDoctor" },
    { text: i18n.t("headers.doctor.name"), value: "name" },
    { text: i18n.t("headers.doctor.last_name"), value: "lastName" },
    { text: i18n.t("headers.doctor.license"), value: "professionalId" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}

export const DECREASE = () => {
  return [
    { text: i18n.t("headers.decrease.id_shipments"), value: "idShipment", width: "8%" },
    { text: i18n.t("headers.decrease.id_order"), value: "orderId", width: "8%" },
    { text: i18n.t("headers.decrease.motive"), value: "shippingReasonText", sortable: false },
    { text: i18n.t("headers.decrease.generated_by"), value: "userFullName", width: "15%" },
    { text: i18n.t("headers.decrease.destination"), value: "branchOfficeDestinationName", width: "25%", },
    { text: i18n.t("headers.decrease.create_date"), value: "createDate", },
    { text: i18n.t("headers.decrease.date_of_shipment"), value: "dateShipment", },
    { text: i18n.t("headers.decrease.status"), value: "statusText", align: 'center', sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center', width: "8%", sortable: false }
  ]
}


export const BRANCH_OFFICE_DASHBOARD = () => {
  return [
    { text: i18n.t("headers.branch_office.tag"), value: "tag" },
    { text: "-", value: "status", width: "5%" },
    { text: i18n.t("headers.branch_office.branch_ID"), value: "idBranchoffice", align: ' d-none' },
    { text: i18n.t("headers.branch_office.warehouse"), value: "name" },
    { text: i18n.t("headers.branch_office.clues"), value: "branchOfficeKey" },
    { text: i18n.t("headers.branch_office.warehouse_id"), value: "warehouseId", align: ' d-none' },
    { text: i18n.t("headers.branch_office.percentage"), value: "value" },
    { text: i18n.t("headers.excel"), value: "actions", sortable: false },
  ]
}

export const PRODUCTS_DASHBOARD = () => {
  return [
    { text: i18n.t("headers.products_dashboard.id_product"), value: "idProduct", align: ' d-none' },
    { text: i18n.t("headers.products_dashboard.key"), value: "productKey" },
    { text: i18n.t("headers.products_dashboard.description"), value: "description" },
    { text: i18n.t("headers.products_dashboard.stocks"), value: "quantity" },
    { text: i18n.t("headers.products_dashboard.dispensing"), value: "quantityPrescription" },
    { text: i18n.t("headers.products_dashboard.level"), value: "level", width: "10%" },
    { text: i18n.t("headers.products_dashboard.min"), value: "min" },
    { text: i18n.t("headers.products_dashboard.max"), value: "max" },
    { text: i18n.t("headers.products_dashboard.supply"), value: "supply", width: "15%" },
    { text: i18n.t("headers.products_dashboard.detailsSupply"), value: "detailsSupply" },
  ]
}

export const PRODUCTS_DASHBOARD_ACRE = () => {
  return [
    { text: i18n.t("headers.products_dashboard_acre.id_product"), value: "idProduct", align: ' d-none' },
    { text: i18n.t("headers.products_dashboard_acre.key"), value: "productKey" },
    { text: i18n.t("headers.products_dashboard_acre.description"), value: "description" },
    { text: i18n.t("headers.products_dashboard_acre.lot"), value: "lot" },
    { text: i18n.t("headers.products_dashboard_acre.expiration"), value: "expirationDate" },
    { text: i18n.t("headers.products_dashboard_acre.stocks"), value: "quantity" },
    { text: i18n.t("headers.products_dashboard_acre.dispensing"), value: "quantityPrescription" },
    { text: i18n.t("headers.products_dashboard_acre.level"), value: "level", width: "15%" }
  ]
}

export const PRESCRIPTION_DASHBOARD = () => {
  return [
    { text: i18n.t("headers.prescription.recipe_ID"), value: "idPrescription" },
    { text: i18n.t("headers.prescription.beneficiary"), value: "medicalRecord" },
    { text: i18n.t("headers.prescription.date_prescription"), value: "datePrescription" },
    { text: i18n.t("headers.prescription.CURP"), value: "curp" },
    { text: i18n.t("headers.prescription.supply_percentage"), value: "supplyPercentage" },
    { text: i18n.t("headers.prescription.PDF"), value: "pdfPrescription" },
  ]
}

export const REMMISION_VIEW = () => {
  return [
    //{ text: "idProductRemmison", value: "idProductRemmison" , sortable: false },
    { text: i18n.t("headers.remmision_view.financing"), value: "fiscalFund.key", sortable: false },
    { text: i18n.t("headers.remmision_view.causes"), value: "products.productKey", sortable: false },
    { text: i18n.t("headers.remmision_view.product"), value: "products.description", sortable: false },
    { text: i18n.t("headers.remmision_view.lot"), value: "lot", sortable: false },
    { text: i18n.t("headers.remmision_view.expiration"), value: "expirationDate", sortable: false },
    { text: i18n.t("headers.remmision_view.price"), value: "unitPrice", sortable: false },
    { text: i18n.t("headers.remmision_view.quantity"), value: "quantity", sortable: false },
  ]
}

export const DISPENSING = () => {
  return [
    { text: i18n.t("headers.dispensing.prescription_type"), value: "prescriptionType", sortable: false },
    { text: i18n.t("headers.dispensing.id_prescription"), value: "idPrescription", sortable: false },
    { text: i18n.t("headers.dispensing.prescriptionKey"), value: "prescriptionKey", sortable: false },
    { text: i18n.t("headers.dispensing.generated_by"), value: "generatedBy", sortable: false },
    { text: i18n.t("headers.dispensing.benificiary"), value: "fullName", sortable: false },
    { text: i18n.t("headers.dispensing.doctor"), value: "doctor.fullName", sortable: false },
    { text: i18n.t("headers.dispensing.create_date"), value: "dateOut", sortable: false },
    { text: i18n.t("headers.dispensing.status"), value: "statusPrescription", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}


export const DISPENSING_IN_HOSPITAL = () => {
  return [
    { text: i18n.t("headers.dispensing_hospital.id_prescription"), value: "idPrescription", sortable: false },
    { text: i18n.t("headers.dispensing_hospital.generated_by"), value: "generatedBy", sortable: false },
    { text: i18n.t("headers.dispensing_hospital.personnel"), value: "employeeName", sortable: false },
    { text: i18n.t("headers.dispensing_hospital.department"), value: "departmentName", sortable: false },
    { text: i18n.t("headers.dispensing_hospital.date_of_issue"), value: "dateOut", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center', align: 'center' }
  ]
}

export const ORDER_PRODUCTS_PRESCRIPTION = () => {
  return [
    { text: i18n.t("headers.order_products_prescription.financing"), value: "fiscalFoundKey" },
    { text: i18n.t("headers.order_products_prescription.cause"), value: "productKey" },
    { text: i18n.t("headers.order_products_prescription.product"), value: "productDescription", width: "35%" },
    { text: i18n.t("headers.order_products_prescription.lot"), value: "lot" },
    { text: i18n.t("headers.order_products_prescription.expiration"), value: "expirationDate" },
    { text: i18n.t("headers.order_products_prescription.dispensed"), value: "quantity" },
    { text: i18n.t("headers.order_products_prescription.prescribed"), value: "prescriptionQuantity" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center', align: 'center' }
  ]
}

export const PRODUCTS_DENIED = () => {
  return [
    { text: i18n.t("headers.products_denied.causes"), value: "product.productKey", sortable: false },
    { text: i18n.t("headers.products_denied.product"), value: "product.completeDescription", sortable: false },
    { text: i18n.t("headers.products_denied.quantity"), value: "quantity", sortable: false },
    { text: i18n.t("headers.products_denied.reason_denied"), value: "reasonDescription", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center', align: 'center' },
  ]
}

export const PRODUCT_STOCK = () => {
  return [
    { text: i18n.t("headers.product_stock.id_product"), value: "idProduct", align: ' d-none' },
    { text: i18n.t("headers.product_stock.cause"), value: "productKey", sortable: false },
    { text: i18n.t("headers.product_stock.product"), value: "completeDescription", sortable: false },
    { text: i18n.t("headers.product_stock.quantity"), value: "quantity", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center', align: 'center' },
  ]
}

export const STOCK_BY_PRODUCT = () => {
  return [
    { text: i18n.t("headers.stock_by_product.id_stock"), value: "idStock" },
    { text: i18n.t("headers.stock_by_product.location"), value: "locationKey", sortable: false },
    { text: i18n.t("headers.stock_by_product.expiration"), value: "expirationDate", sortable: false },
    { text: i18n.t("headers.stock_by_product.lot"), value: "lote", sortable: false },
    { text: i18n.t("headers.stock_by_product.financing"), value: "fiscalFund", sortable: false },
    { text: i18n.t("headers.stock_by_product.status"), value: "status", sortable: false },
    { text: i18n.t("headers.stock_by_product.current_quantity"), value: "quantity", sortable: false },
    { text: i18n.t("headers.stock_by_product.quantity_new"), value: "quantityNew", align: ' d-none' },
    { text: i18n.t("headers.stock_by_product.edit_quantity"), value: "actions", sortable: false, align: 'center' },
  ]
}

export const PRODUCT_PREREQUEST = () => {
  return [
    { text: i18n.t("headers.product_prerequest.causes"), value: "products.productKey" },
    { text: i18n.t("headers.product_prerequest.product"), value: "products.name", align: 'center' },
    { text: i18n.t("headers.product_prerequest.quantity"), value: "quantityOrdersProduct", align: 'center' },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center', align: 'center' },
  ]
}

export const PRODUCT_PREREQUEST_DISTRIBUTION = () => {
  return [
    { text: i18n.t("headers.product_prerequest.product"), value: "text" },
    { text: i18n.t("headers.product_prerequest.quantity"), value: "quantity", width: "15%" },
  ]
}

export const BRANCH_OFFICE_USER = () => {
  return [
    { text: i18n.t("user.name"), value: "name", width: "50%" },
    { text: i18n.t("user.clues"), value: "branchOfficeKey" },
/*     { text: i18n.t("headers.stock_by_product.status"), value: "status" },
 */  ];
};

export const USER_SESSION_HISTORY = () => {
  return [
    /* { text: "Tipo", value: "name", width: "50%" }, */
    { text: "Fecha de Ingreso", value: "accessDate", align: "center" },
    /* { text: i18n.t("user.clues"), value: "branchOfficeKey" }, */
  ];
};

export const STOCK_OBSERVATION = () => {
  return [
    { text: i18n.t("headers.stock_observation.id_stock"), value: "idStock" },
    { text: i18n.t("headers.stock_observation.causes"), value: "products.productKey" },
    { text: i18n.t("headers.stock_observation.product"), value: "products.completeDescription", width: "30%" },
    { text: i18n.t("headers.stock_observation.lot"), value: "lote" },
    { text: i18n.t("headers.stock_observation.expired"), value: "expirationDate" },
    { text: i18n.t("headers.stock_observation.fiscal_found"), value: "fiscalFund" },
    { text: i18n.t("headers.stock_observation.quantity"), value: "quantity" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false }
  ];
};

export const DISTRIBUTION_BOARD = () => {
  return [
    /* { text: "ID Distribución", value: "idDistributionBoard" }, */
    { text: i18n.t("headers.distribution_board.name"), value: "name" },
    { text: i18n.t("headers.distribution_board.description"), value: "description" },
    { text: i18n.t("headers.distribution_board.date_created"), value: "dateDistribution" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false }
  ]
}


export const BRANCH_OFFICE_DISTRIBUTION_BOARD = () => {
  return [
    { text: i18n.t("user.name"), value: "name", width: "50%" },
    { text: i18n.t("user.clues"), value: "branchOfficeKey" },
    //{ text: i18n.t("user.id_warehouse"), value: "warehouseId" },
  ];
};


export const PRODUCTS_DISTRIBUTION_BOARD = () => {
  return [
    { text: i18n.t("headers.products.causes"), value: "productKey" },
    { text: i18n.t("headers.products.description"), value: "description" },
  ]
}

export const PRERESQUEST = () => {
  return [
    { text: "ID Solicitud", value: "idShipmentRequest", width: "8%" },
    { text: "Referencia", value: "requestKey", width: "10%" },
    { text: "Tipo de solicitud", value: "typeRequestName", width: "10%" },
    { text: "Sucursal de aprovisionamiento", value: "destination.name" },
    { text: "Generada por", value: "userBranchOffice.user.fullName" },
    { text: "Fecha de creación", value: "dateRequest" },
    { text: "Estatus", value: "statusRequestName" },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' },
  ]
}

export const PRERESQUEST_VALIDATE = () => {
  return [
    { text: "ID solicitud", value: "idShipmentRequest", width: "8%" },
    { text: "Referencia", value: "requestKey", width: "10%" },
    { text: "Tipo de solicitud", value: "typeRequestName", width: "10%" },
    { text: "Sucursal de origen", value: "userBranchOffice.branchOffice.fullName" },
    { text: "Sucursal de aprovisionamiento", value: "destination.name" },
    { text: "Generada por", value: "userBranchOffice.user.fullName" },
    { text: "Fecha de creación", value: "dateRequest", width: "10%" },
    { text: "Estatus", value: "statusRequestName", },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' },
  ]
}

export const PRERESQUEST_PRODUCT_CNIS = () => {
  return [
    { text: "Causes", value: "products.productKey", },
    { text: "Nombre", value: "products.name" },
    { text: "Cantidad", value: "quantityOrdersProduct", width: "12%", align: 'center' },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' },
  ]
}

export const PRERESQUEST_PRODUCT_CNIS_AUTORIZE = () => {
  return [
    { text: "Causes", value: "products.productKey" },
    { text: "Nombre", value: "products.description" },
    { text: "Cantidad", value: "quantityOrdersProduct", width: "12%", align: 'center' },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' },
  ]
}

export const PRERESQUEST_PRODUCT_DISTRIBUTION = () => {
  return [
    { text: "Nombre", value: "products.description" },
    { text: "Cantidad", value: "quantityOrdersProduct", width: "12%", align: 'center' },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' },
  ]
}

export const CONFIG_USERS_DASBOARD_ABASTO = () => {
  return [
    { text: "Correo electrónico", value: "userEmail", sortable: true },
    { text: "Nombre", value: "userName", sortable: true },
    { text: "Nivel", value: "userLevel", sortable: true },
    { text: "Jurisdicción", value: "jurisdictionName", align: 'center', sortable: false },
    /*  { text: "No. Sucursales", value: "userNoBranchOffice", align: 'center', sortable: false, }, */
    { text: "Cuadro Principal", value: "distributionBoardName", align: 'center', sortable: true },
    { text: "Tablero Abasto", value: "availableDashboard", align: 'center', width: "12%", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center', sortable: false },
  ]
}

export const CONFIG_USERS_DASBOARD_STATISTIC = () => {
  return [
    { text: "Correo electrónico", value: "userEmail", sortable: true },
    { text: "Nombre", value: "userName", sortable: true },
    { text: "Tablero Estadistica", value: "availableDashboard", align: 'center', sortable: false },
  ]
}
export const NON_COMPLIANT_PRODUCT_HISTORY = () => {
  return [
    { text: i18n.t("headers.non_compliant_product.id_health_alert"), value: "idHealthAlert", width: "10%", sortable: false },
    { text: i18n.t("headers.non_compliant_product.generated_by"), value: "generatedBy", width: "25%", sortable: false },
    { text: i18n.t("headers.non_compliant_product.motive"), value: "motive", width: "25%", sortable: false },
    { text: i18n.t("headers.non_compliant_product.issue_date"), value: "createDateText", sortable: false },
    { text: i18n.t("headers.non_compliant_product.status"), value: "status", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, width: "15%", align: 'center' }
  ];
};

export const NON_COMPLIANT_PRODUCT_SEARCH = () => {
  return [
    { text: i18n.t("headers.non_compliant_product_search.branch"), value: "branchOfficeOrigin", width: "16%" },
    { text: i18n.t("headers.non_compliant_product_search.id_stock"), value: "stockId", width: "8%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search.causes"), value: "productKey", width: "8%" },
    { text: i18n.t("headers.non_compliant_product_search.product"), value: "completeDescription", width: "30%" },
    { text: i18n.t("headers.non_compliant_product_search.lot"), value: "lot", width: "6%" },
    { text: i18n.t("headers.non_compliant_product_search.expiration"), value: "expirationDate", width: "10%" },
    { text: i18n.t("headers.non_compliant_product_search.supplier"), value: "nameSupplier", width: "12%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search.quantity"), value: "quantity", width: "5%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search.status"), value: "disable", width: "5%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search.transit"), value: "inTransit", sortable: false },
  ];
};
export const NON_COMPLIANT_PRODUCT_SEARCH_QUANTITY_TO_BLOCK = () => {
  return [
    { text: i18n.t("headers.non_compliant_product_search.id_stock"), value: "idStock", width: "8%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search.causes"), value: "productKey", width: "8%" },
    { text: i18n.t("headers.non_compliant_product_search.product"), value: "completeDescription", width: "30%" },
    { text: i18n.t("headers.non_compliant_product_search.lot"), value: "lot", width: "6%" },
    { text: i18n.t("headers.non_compliant_product_search.expiration"), value: "expirationDate", width: "12%" },
    { text: i18n.t("headers.non_compliant_product_search.supplier"), value: "nameSupplier", width: "12%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search.quantity"), value: "quantity", width: "10%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search.quantity_to_block"), value: "quantityToBlock", width: "8%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search.transit"), value: "transit", width: "10%", sortable: false },
  ];
};
export const NON_COMPLIANT_PRODUCT_SEARCH_DETAILS = () => {
  return [
    { text: i18n.t("headers.non_compliant_product_search.branch"), value: "branchOfficeOrigin", width: "16%" },
    { text: i18n.t("headers.non_compliant_product_search.id_stock"), value: "stockId", width: "8%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search.causes"), value: "productKey", width: "8%" },
    { text: i18n.t("headers.non_compliant_product_search.product"), value: "completeDescription", width: "300px" },
    { text: i18n.t("headers.non_compliant_product_search.lot"), value: "lot", width: "6%" },
    { text: i18n.t("headers.non_compliant_product_search.expiration"), value: "expirationDate", width: "105px" },
    { text: i18n.t("headers.non_compliant_product_search.supplier"), value: "nameSupplier", width: "150px", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search.quantity"), value: "quantity", width: "5%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search.status"), value: "disable", width: "5%", },
    { text: i18n.t("headers.non_compliant_product_search.movement"), value: "statusText", width: "5%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search.transit"), value: "inTransit", sortable: false },
  ];
};
export const ON_TRANSIT = () => {
  return [
    { text: i18n.t("headers.on_transit.ID_shipment"), value: "idShipment", width: "12%" },
    { text: i18n.t("headers.on_transit.ID_starting_order"), value: "idOrder", width: "12%" },
    { text: i18n.t("headers.on_transit.destination"), value: "branchOfficeDestination", width: "35%" },
    { text: i18n.t("headers.on_transit.quantity_on_transit"), value: "quantity", width: "4%" },
    { text: i18n.t("headers.on_transit.shipment_date"), value: "shipmentDate", width: "12%" },
    { text: i18n.t("headers.on_transit.order_date"), value: "orderDate", width: "12%" },
    { text: i18n.t("headers.on_transit.status"), value: "statusShipmentText", sortable: false },
  ];
};

export const NON_COMPLIANT_PRODUCT_SEARCH_FORM = () => {
  return [
    { text: i18n.t("headers.non_compliant_product_search_form.number"), value: "product.number", width: "5%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search_form.causes"), value: "product.productKey", width: "15%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search_form.description"), value: "product.description", width: "25%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search_form.unitMeasurement"), value: "product.unitMeasurementDescription", width: "15%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search_form.lot"), value: "lot", width: "10%", sortable: false },
    { text: i18n.t("headers.non_compliant_product_search_form.supplier"), value: "supplier.text", width: "12%", sortable: false },
    { text: i18n.t("headers.actions"), value: "actions", width: "2%", align: 'center', sortable: false }
  ];
};


export const REMMISION_AUDIT = () => {
  return [
    { text: i18n.t("headers.remmision.remmision_id"), value: "idRemmision", align: 'center' },
    { text: "Factura", value: "invoice", align: 'center'  },
    { text: "Sucursal de origen", value: "branchOffice", align: 'center'  },
    { text: "Referencia", value: "remissionKey", align: 'center'  },
    { text: "Generada por", value: "userFullName", align: 'center'  },
    { text: i18n.t("headers.remmision.type_remission"), value: "requisitionType", align: 'center'  },
    { text: i18n.t("headers.remmision.order"), value: "requisitioKey", align: 'center'  },
    { text: i18n.t("headers.remmision.registration_date"), value: "dateInput", align: 'center'  },
    //{ text: "Observaciones", value: "observations", align: 'center'  },

    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ];
};

export const REMMISION_AUDIT_MOVEMENT = () => {
  return [
    { text: "ID Movimiento", value: "idMovement", align: 'center' },
    { text: "Sucursal de origen", value: "branchOfficeMovement", sortable: false },
    { text: "Fecha de movimiento", value: "dateMovement", align: 'center'  },
    { text: "Modulo", value: "entity", align: 'center', sortable: false  },
    { text: "Descripción Movimiento", value: "originMovement", align: 'center', sortable: false  },
    { text: "Tipo de movimiento", value: "movement", align: 'center', sortable: false  },
    { text: "ID Principal", value: "idPrincipal", align: 'center'  },
    { text: "ID Secundario", value: "idSecondary", align: 'center'  },
    { text: "Realizado por", value: "userName", align: 'center', sortable: false,  },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
    ];
};


export const STARTING_ORDER_AUDIT = () => {
  return [
    { text: i18n.t("headers.starting_order.ID_shipment"), value: "idShipment", align: 'center' },
    { text: i18n.t("headers.starting_order.ID_starting_order"), value: "orderId", align: 'center' },
    { text: "Tipo de salida", value: "shippingReasonText", align: 'center' },
    { text: "Sucursal de origen", value: "branchOfficeOriginName", align: 'center'  },
    { text: i18n.t("headers.starting_order.generated_by"), value: "userFullName", align: 'center' },
    { text: i18n.t("headers.starting_order.destination"), value: "branchOfficeDestinationName", align: 'center' },
    { text: i18n.t("headers.starting_order.create_date"), value: "dateShipment", align: 'center' },
    //{ text: i18n.t("headers.starting_order.date_of_shipment"), value: "dateShipment", align: 'center' },
    { text: i18n.t("headers.starting_order.status"), value: "statusShipment",align: 'center' },
    { text: i18n.t("headers.actions"), value: "actions", sortable: false, align: 'center' }
  ]
}